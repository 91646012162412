import { createI18n } from 'vue-i18n'
// import en from "./locales/en.json"
// import nl from "./locales/nl.json"

const numberFormats = {
  en: {
    currencyFormat: {
      style: "currency",
      currency: "USD"
    }
  },
  nl: {
    currencyFormat: {
      style: "currency",
      currency: "EUR"
    }
  }
}

const datetimeFormats = {
  en: {
    shortFormat: {
      dateStyle: "short"
    },
    longFormat: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  nl: {
    shortFormat: {
      dateStyle: "short"
    },
    longFormat: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  }
 }

const messages = {
  en: {
    login: {
      username: "Username or Email",
      password: "Password",
      forgot_password: "Forgot password?",
      // log_in: "Log In",
      not_have_account: "Don't have an account?",
      create_account: "Create an account"
    },
    signup: {
      firstname: "Firstname",
      lastname: "Lastname",
      kbo_number: "KBO Number",
      create_account_btn: "Create Account",
      have_account: "Already have an account?"
    },
    menu: {
      my_profile: "My Profile",
      lbl_patients: 'Patients',
      lbl_account: 'Account',
      lbl_overview: 'Overview',
      threpists: 'Threpists',
      active: "Active",
      inactive: "Inactive",
      new: "New",
      password: "Password"
    },
    activePatients: {
      title: "Active Patients",
    },
    inactivePatients: {
      title: "Inactive Patients",
    },
    newPatient: {
      title: "New Patient",
    },
    editPatient: { // EditPatientView.vue
      title: "Patient Profile",
      profile: "Profile",
      sessions: "Sessions",
      general: "General",
      treatment: "Treatment",
      documents: "Documents",
      delete_modal: {
        title: "Delete patient",
        hint: "of this patient will also be deleted."
      }
    },
    sessionsView: { // PatientSessionsView.vue
      new_session: "New Session",
      edit_session: "Edit Session",
      reload_sessions: "Reload Sessions",
      sessions: "Sessions",
      session: "Session",
      paid: "Paid",
      unpaid: "Unpaid",
      on: "on",
      loginPatientTherapistSessions_title: "Therapist Sessions",
      delete_modal: {
        title: "Delete Session"
      }
    },
    editSession: { // EditPatientSessionView.vue
      session_list: "Session List",
      date: "Date",
      start_time: "Start Time",
      end_time: "End Time",
      payment_status: "Payment Status",
      amount: "Amount",
      paid_on: "Paid On"
    },
    contacts: { // PatientContacts.vue
      new_contact: "New Contact",
      edit_contact: "Edit Contact",
      reload_contacts: "Reload Contacts",
      contact: "Contact",
      delete_modal: {
        title: "Delete Contact"
      }
    },

    forgotPassword: {
      title: "Forgot password?",
      hint: "Enter the email address you used to login Cotinotis and we will send you instructions to reset the password.",
      password_reset_btn: "Request password reset"
    },
    changePassword: {
      // ChangePassword.vue & ModifyPassword.vue
      set_password_title: "Set new password",
      new_password: "New password",
      confirm_password: "Confirm new password",
      set_password_btn: "Set new password",
      one_lowercase: "At least one lowercase letter",
      one_uppercase: "At least one uppercase letter",
      one_number: "At least one number",
      no_space: "No white space",
      min_chars: "Minimum of 8 characters",
      // ModifyPassword.vue
      modify_password_title: "Change password",
      modify_password_hint: "You will need to login again after changing your password.",
      current_password: "Current password",
      // cancel_btn: "Cancel"
    },
    profile: { // TherapistPersonal.vue
      personal_subtitle: "Personal Information",
      firstname: "First name",
      lastname: "Last name",
      nationality: "Nationality",
      birth_date: "Date of Birth",
      gender: "Gender",
      male: "Male",
      female: "Female",
      personal_id: "Personal Identification Number",
      phone: "Telephone",
      mobile: "Mobile",
      location_subtitle:"Location Information",
      street_name: "Street name",
      street_no: "Street number",
      city: "City",
      zip: "Postal code",
      // save_btn: "Save",
      // close_btn: "Close",
      additional_subtitle:"Additional Information",
      health_insurance_company: "Health insurance company",
      highest_education: "Highest level of education",
      medication: "Medication",
      remarks: "Remarks",
      relation: "Relation" // for add/edit contact
    },
    documents: {
      title: "Documents",
      add_google_files_btn: "Add Files from Google Drive",
      filename: "File name",
      create_date: "Creation date",
      actions: "Actions",
      view: "View",
      delete_modal: {
        title: "Delete Document?",
        delete: "Delete",
        hint: "Files on your Google drive remain unchanged."
      },
      document_plural: "Documents | Document | Documents",
      add_from_google_drive: "Add from Google Drive?"
    },
    patientList: {
      new_patient: "New Patient",
      no_phone: "No telephone in the record",
      no_birthdate: "No birth date in the record",
      no_practitioner: "No general practitioner in the record",
      no_insurance: "No health insurance in the record",
      therapist: "Therapist",
      no_mobile: "No mobile in the record",
      no_street: "No street in the record",
      no_city: "No city in the record",
      patient: "Patient"
    },
    shared: {
      reload: "Reload",
      save_btn: "Save",
      close_btn: "Close",
      cancel_btn: "Cancel",
      delete_btn: "Delete",
      add_btn: "Add",
      loading: "Loading...",
      saving: "Saving...",
      deleting: "Deleting...",
      adding: "Adding",
      not_undo_action: "You cannot undo this action.",
      no_data_available: "No data available"
    },
  },
  nl: {
    login: {
      username: "Gebruikersnaam of Email",
      password: "Wachtwoord",
      forgot_password: "Wachtwoord vergeten?",
      not_have_account: "Heb je geen account?",
      create_account: "Een account aanmaken"
    },
    signup: {
      firstname: "Voornaam",
      lastname: "Achternaam",
      kbo_number: "KBO Nummer",
      create_account_btn: "Account aanmaken",
      have_account: "Heb je al een account?"
    },
    menu: {
      my_profile: "Mijn Profiel",
      lbl_patients: 'Patiënten',
      lbl_account: 'Account',
      lbl_overview: 'Overzicht',
      threpists: "Therapeuten",
      active: "Actief",
      inactive: "Inactief",
      new: "Nieuw",
      password: "Wachtwoord"
    },
    activePatients: {
      title: "Actieve Patiënten",
    },
    inactivePatients: {
      title: "Inactieve Patiënten",
    },
    newPatient: {
      title: "Nieuwe Patiënt",
    },
    editPatient: {
      title: "Patiëntgegevens",
      profile: "Profiel",
      sessions: "Sessies",
      general: "Algemeen",
      treatment: "Behandeling",
      documents: "Documenten",
      delete_modal: {
        title: "Verwijder patiënt",
        hint: "van deze patient worden ook verwijderd."
      }
    },
    sessionsView: { // PatientSessionsView.vue
      new_session: "Nieuwe Sessie",
      edit_session: "Bewerk Sessie",
      reload_sessions: "Sessies opnieuw laden",
      sessions: "Sessies",
      session: "Sessie",
      paid: "Betaald",
      unpaid: "Onbetaald",
      on: "op",
      loginPatientTherapistSessions_title: "Therapeutensessies",
      delete_modal: {
        title: "Verwijder sessie"
      }
    },
    editSession: { // EditPatientSessionView.vue
      session_list: "Overzicht sessies",
      date: "Datum",
      start_time: "Starttijd",
      end_time: "Eindtijd",
      payment_status: "Betalingsstatus",
      amount: "Bedrag",
      paid_on: "Betaald op"
    },
    contacts: { // PatientContacts.vue
      new_contact: "Nieuwe Contact",
      edit_contact: "Bewerk Contact",
      reload_contacts: "Contacts opnieuw laden",
      contact: "Contact",
      delete_modal: {
        title: "Verwijder contact"
      }
    },
    forgotPassword: {
      title: "Wachtwoord vergeten?",
      hint: "Geef het e-mailadres op waarmee je aanmeldt op Cotinotis zodat we je een link kunnen toesturen om je wachtwoord opnieuw in te stellen.",
      password_reset_btn: "Wachtwoord reset aanvragen"
    },
    changePassword: { // // ChangePassword.vue and ModifyPasswordView.vue
      set_password_title: "Nieuw wachtwoord instellen",
      new_password: "Nieuw Password",
      confirm_password: "Bevestig nieuw wachtwoord",
      set_password_btn: "Nieuw wachtwoord instellen",
      one_lowercase: "Ten minste een kleine letter",
      one_uppercase: "Ten minste een hoofdletter",
      one_number: "Minstens één nummer",
      no_space: "Geen witruimte",
      min_chars: "Minimaal 8 tekens",
      // ModifyPasswordView.vue
      modify_password_title: "Verander wachtwoord",
      modify_password_hint: "Na het wijzigen van uw wachtwoord moet u opnieuw inloggen.",
      current_password: "Huidig wachtwoord",
      // cancel_btn: "Annuleren"
    },
    profile: { // TherapistPersonal.vue 
      personal_subtitle: "Persoonlijke Informatie",
      firstname: "Voornaam",
      lastname: "Achternaam",
      nationality: "Nationaliteit",
      birth_date: "Geboortedatum",
      gender: "Geslacht",
      male: "Man",
      female: "Vrouw",
      personal_id: "Rijksregisternummer",
      phone: "Telefoon",
      mobile: "Mobiel",
      location_subtitle:"Locatie Informatie",
      street_name: "Straatnaam",
      street_no: "Straatnummer",
      city: "Stad",
      zip: "Postcode",
      // save_btn: "Bewaren",
      // close_btn: "Sluiten",
      additional_subtitle:"Extra Informatie",
      health_insurance_company: "Mutualiteit",
      highest_education: "Hoogste opleidingsniveau",
      medication: "Medicatie",
      remarks: "Opmerkingen",
      relation: "Relatie" // for add/edit contact
    },
    documents: {
      title: "Documenten",
      add_google_files_btn: "Bestanden toevoegen vanuit Google Drive",
      filename: "Bestandsnaam",
      create_date: "Datum aanmaak",
      actions: "Acties",
      view: "Bekijken",
      delete_modal: {
        title: "Document verwijderen?",
        delete: "Verwijder",
        hint: "Bestanden op uw Google drive wijzigen niet."
      },
      document_plural: "Documenten | Document | Documenten",
      add_from_google_drive: "Toevoegen vanuit Google Drive?"
    },
    patientList: {
      new_patient: "Nieuwe Patiënt",
      no_phone: "Geen telefoon in het dossier",
      no_birthdate: "Geen geboortedatum in het dossier",
      no_practitioner: "Geen huisarts in het dossier",
      no_insurance: "Geen mutualiteit opgegeven in het dossier",
      therapist: "Therapeut",
      no_mobile: "Geen GSM in het dossier",
      no_street: "Geen straat in het dossier",
      no_city: "Geen stad in het dossier",
      patient: "Patiënt"
    },
    shared: {
      reload: "Opnieuw Laden",
      save_btn: "Opslaan",
      close_btn: "Sluiten",
      cancel_btn: "Annuleren",
      delete_btn: "Verwijderen",
      add_btn: "Toevoegen",
      loading: "Ophalen...",
      saving: "Opslaan...",
      deleting: "Verwijderen...",
      adding: "Toevoegen...",
      not_undo_action: "Deze actie kan niet ongedaan gemaakt worden.",
      no_data_available: "Geen gegevens beschikbaar"
    },
    home: {
      test: "Documenten | Document | Documenten",
      header: "Welkom bij de Vue 3 I18n-zelfstudie!",
      created_by: "Deze tutorial is u aangeboden door {company}.",
      num_visits: "Deze pagina is niet bezocht :( | Deze pagina is {n} keer bezocht | Deze pagina is {n} keer bezocht"
    },
    about: {
      header: "Over ons",
      donations: "Opgehaalde donaties: {donations}"
    }
  },
}

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: 'nl', // set locale
  fallbackLocale: 'en', // set fallback locale

  // locale: import.meta.env.VITE_DEFAULT_LOCALE,
  // fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  messages, // set locale messages
  // messages: { en, nl },
  numberFormats,
  datetimeFormats
  // If you need to specify other options, you can set other options
  // ...
})

export default i18n
