import { createStore, createLogger } from "vuex";
import router from "../router";
import patients from "./patients";
import loginPatient from "./loginPatient";
import params from "./params";
import { axiosInstance } from "@/plugins/axios";

const store = createStore({
  plugins: [createLogger()],
  state: {
    user: null,
    // user: {
    //   ID: 1,
    //   PersonID: 1,
    //   TherapistID: 1,
    //   Naam: "Michael Van Canneyt",
    //   Email: "michael.vancanneyt@gmail.com",
    //   Login: "michael",
    //   Roles: "Therapist",
    //   Permissions: 15,
    //   Token:
    //     "MTt7OEY2QzI4QkItQ0M4Qy00QTMyLTk3NDctQ0VDNDdBRTE3NTk5fTsyMDIzLTAxLTA4VDE1OjQ3OjM5Ljc2NVo=",
    // },

    token: null,
    error: { title: null, desp: null },
    info: { title: null, desp: null },
    loading: false,
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
  },
  mutations: {
    SET_AUTH_USER(state, payload) {
      state.user = payload;
      console.log("SET_USER_AUTH - user", state.user);
      // localStorage.setItem("cotinotisUser", JSON.stringify(state.user));
    },
    UPDATE_AUTH_TOKEN(state, token) {
      // console.log("UPDATE_AUTH_TOKEN - token", token);
      if (token && state.token !== token) {
        state.token = token;
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        console.log("UPDATE_AUTH_TOKEN - updated state.token!");
      } else {
        console.log(
          "UPDATE_AUTH_TOKEN - state.token no change or not exist",
          token
        );
      }
      const localStorageToken = localStorage.getItem("cotinotisToken");
      if (token && localStorageToken !== token) {
        localStorage.setItem("cotinotisToken", token);
        console.log("UPDATE_AUTH_TOKEN - updated localStorage token!");
      } else {
        console.log(
          "UPDATE_AUTH_TOKEN - localStorage token no change or not exist",
          token
        );
      }
    },
    CLEAR_AUTH_USER(state) {
      state.user = null;
      state.token = null;
      delete axiosInstance.defaults.headers.common["Authorization"];
      localStorage.removeItem("cotinotisToken");
      // localStorage.removeItem("cotinotisUser");
      console.log("CLEAR_AUTH_USER called");
    },
    UPDATE_ERROR(state, { title, desp }) {
      if (state.error.title === title && state.error.desp === desp) return;
      state.error.title = title;
      state.error.desp = desp;
      console.log("UPDATE_ERROR called", JSON.stringify(state.error));
    },
    UPDATE_INFO(state, { title, desp }) {
      if (state.info.title === title && state.info.desp === desp) return;
      state.info.title = title;
      state.info.desp = desp;
      console.log("UPDATE_INFO called", state.info);
    },
    CLEAR_ERROR_INFO(state) {
      state.error.title = null;
      state.error.desp = null;
      state.info.title = null;
      state.info.desp = null;
      console.log("CLEAR_ERROR_INFO called");
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
      console.log("SET_LOADING called", loading);
    },
  },
  actions: {
    async login({ commit, dispatch }, { username, password }) {
      console.log(`login with ${username} / ${password}`, router);
      commit("CLEAR_AUTH_USER");
      const res = await axiosInstance
        .post("/RPC", {
          jsonrpc: "2.0",
          id: "21",
          class: "User",
          method: "login",
          // params: ["michael", "geen1"],
          params: [username, password],
        })
        .catch((e) => {
          console.log("catch login error", e.message);
          throw new Error(e);
        });
      if (res.status === 200 && res.data) {
        console.log("login res.data", res.data);
        if (res.data.error) {
          throw new Error(`${res.data.error.message} (${res.data.error.code})`);
        } else if (res.data.result === null) {
          throw new Error(`Invalid username or password.`);
        } else {
          console.log("login res.data.result", res.data.result);
          const user = res.data.result;
          const token = user.Token;
          commit("UPDATE_AUTH_TOKEN", token);
          delete user.Token;
          commit("SET_AUTH_USER", user);
          console.log("login call fetchParams()");
          dispatch("params/fetchParams", null);
          if (user.Roles === "Patient") {
            router.push({
              name: "LoginPatientTherapists",
            });
            console.log("login - router pushed LoginPatientTherapists");
          } else if (user.Roles === "Therapist") {
            router.push({
              name: "ActivePatients",
            });
            console.log("login - router pushed ActivePatients");
          }
        }
      } else {
        throw new Error(`Invalid login response (${res.status}).`);
      }
    },

    async tryAutoSignIn({ state, commit, dispatch }) {
      console.log("tryAutoSignIn - state.user", state.user);
      if (!state.user) {
        const token = localStorage.getItem("cotinotisToken");
        if (token) {
          console.log("tryAutoSignIn - call sendCheckTokenRpc");
          // 1. sendCheckTokenRpc
          try {
            const result = await dispatch("sendCheckTokenRpc", token);
            // console.log("tryAutoSignIn - sendCheckTokenRpc result", result);
            if (result === "OK") {
              console.log("tryAutoSignIn - sendCheckTokenRpc OK!");
            }
          } catch (err) {
            console.log(
              "tryAutoSignIn - sendCheckTokenRpc error, logout!",
              err
            );
            // dispatch("logout", `${err.message}`);
            // commit("UPDATE_ERROR", {
            //   title: "Check token",
            //   desp: `${err.message}`,
            // });
            commit("UPDATE_INFO", {
              title: "Logout",
              desp: "Session ended due to invalid token.",
            });
            commit("CLEAR_AUTH_USER");
            commit("patients/CLEAR_STATE");
            commit("loginPatient/CLEAR_STATE");
            commit("params/CLEAR_STATE");
            console.log("tryAutoSignIn - router.push to Login!");
            router.push({
              name: "Login",
            });
          }
        }
      } else {
        console.log("tryAutoSignIn - nothing to do");
      }
    },

    async logout({ commit }) {
      console.log(`logout called, process.env.NODE_ENV`, process.env.NODE_ENV);
      commit("UPDATE_ERROR", { title: null, desp: null });
      try {
        const res = await axiosInstance.post("/RPC", {
          jsonrpc: "2.0",
          id: "21",
          class: "User",
          method: "logout",
          params: [],
        });
        if (res.status === 200 && res.data) {
          console.log("logout res.data", res.data);
          if (res.data.error) {
            // throw new Error(`${res.data.error.message} (${res.data.error.code})`);
            console.log("logout res.data.error", res.data.error);
            commit("UPDATE_ERROR", {
              title: "Log Out",
              desp: `${res.data.error.message} (${res.data.error.code})`,
            });
          } else {
            console.log("logout res.data.result", res.data.result);
          }
        } else {
          console.log("logout error occurred", res.status);
          if (process.env.NODE_ENV === "development") {
            commit("UPDATE_ERROR", {
              title: "Log Out",
              desp: `Invalid response (${res.status}).`,
            });
          }
        }
      } catch (e) {
        console.log("catch logout error", e.message);
        commit("UPDATE_ERROR", { title: "Log Out", desp: e.message });
      } finally {
        commit("CLEAR_AUTH_USER");
        commit("patients/CLEAR_STATE");
        commit("loginPatient/CLEAR_STATE");
        commit("params/CLEAR_STATE");
        console.log("logout router.push to Login!");
        router.push({
          name: "Login",
        });
      }
    },

    async sendCheckTokenRpc({ commit }, token) {
      console.log(`sendCheckTokenRpc - ${token}`);
      commit("CLEAR_ERROR_INFO");

      const res = await axiosInstance
        .post("/RPC", {
          jsonrpc: "2.0",
          id: "21",
          class: "User",
          method: "CheckToken",
          params: [token],
        })
        .catch((e) => {
          console.log("catch sendCheckTokenRpc error", e.message);
          throw new Error(e);
        });
      if (res.status === 200 && res.data) {
        console.log("sendCheckTokenRpc res.data", res.data);
        if (res.data.error) {
          throw new Error(`${res.data.error.message} (${res.data.error.code})`);
        } else if (res.data.result == null) {
          console.log("sendCheckTokenRpc - Invalid token", res.data.result);
          throw new Error(`Invalid token.`);
        } else {
          console.log("sendCheckTokenRpc res.data.result", res.data.result);
          const user = res.data.result;
          const token = user.Token;
          commit("UPDATE_AUTH_TOKEN", token);
          delete user.Token;
          commit("SET_AUTH_USER", user);
          return "OK";
        }
      } else {
        throw new Error(`Invalid response (${res.status}).`);
      }
    },

    async sendChangePasswordRpc({ commit }, userEmail) {
      console.log(`sendChangePasswordRpc with ${userEmail}`);
      commit("CLEAR_ERROR_INFO");
      const res = await axiosInstance
        .post("/RPC", {
          jsonrpc: "2.0",
          id: "21",
          class: "User",
          method: "SendChangePasswordLink",
          params: [userEmail],
        })
        .catch((e) => {
          console.log("catch sendChangePasswordRpc error", e.message);
          throw new Error(e);
        });
      if (res.status === 200 && res.data) {
        console.log("sendChangePasswordRpc res.data", res.data);
        if (res.data.error) {
          throw new Error(`${res.data.error.message} (${res.data.error.code})`);
        } else if (res.data.result !== true) {
          console.log("sendChangePasswordRpc res.data.result", res.data.result);
          throw new Error(
            `Request password reset failed (Result: ${res.data.result})`
          );
        } else {
          // commit("UPDATE_INFO", {
          //   title: "Request Password Reset successful",
          //   desp: `Please check your mailbox`,
          // });
          return "OK";
        }
      } else {
        throw new Error(`Invalid response (${res.status}).`);
      }
    },

    async setNewPasswordRpc({ commit }, paramArr) {
      console.log(`setNewPasswordRpc with ${paramArr}`);
      // commit("SET_LOADING", true);
      commit("CLEAR_ERROR_INFO");
      const res = await axiosInstance
        .post("/RPC", {
          jsonrpc: "2.0",
          id: "23",
          class: "User",
          method: "ChangePassword",
          params: paramArr,
        })
        .catch((e) => {
          console.log("catch setNewPasswordRpc error", e.message);
          throw new Error(e);
        });
      if (res.status === 200 && res.data) {
        console.log("setNewPasswordRpc res.data", res.data);
        if (res.data.error) {
          throw new Error(`${res.data.error.message} (${res.data.error.code})`);
        } else if (res.data.result !== true) {
          console.log("setNewPasswordRpc res.data.result", res.data.result);
          throw new Error(
            `Set new password failed (Result: ${res.data.result})`
          );
        } else {
          // commit("UPDATE_INFO", {
          //   title: "Request Password Reset successful",
          //   desp: `Please check your mailbox`,
          // });
          return "OK";
        }
      } else {
        throw new Error(`Invalid response (${res.status}).`);
      }
    },

    async signupRpc({ commit }, paramArr) {
      console.log(`signupRpc with ${paramArr}`);
      // commit("SET_LOADING", true);
      commit("CLEAR_ERROR_INFO");
      const res = await axiosInstance
        .post("/RPC", {
          jsonrpc: "2.0",
          id: "25",
          class: "Therapist",
          method: "RegisterTherapist",
          params: paramArr,
        })
        .catch((e) => {
          console.log("catch signupRpc error", e.message);
          throw new Error(e);
        });
      if (res.status === 200 && res.data) {
        console.log("signupRpc res.data", res.data);
        if (res.data.error) {
          throw new Error(`${res.data.error.message} (${res.data.error.code})`);
        } else if (!res.data.result) {
          console.log("signupRpc res.data.result", res.data.result);
          throw new Error(
            `Set new password failed (Result: ${res.data.result})`
          );
        } else if (res.data.result.username) {
          // commit("UPDATE_INFO", {
          //   title: "Request Password Reset successful",
          //   desp: `Please check your mailbox`,
          // });
          return "OK";
        }
      } else {
        throw new Error(`Invalid response (${res.status}).`);
      }
    },
  },
  modules: { patients, loginPatient, params },
});

export default store;
