import { axiosInstance } from "@/plugins/axios";

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  namespaced: true,
  state: {
    therapistsList: [],

    // store login patient profile or login therapist's patient profile
    personRec: null,
    patientRec: null,
  },
  getters: {
    getTherapistById: (state) => (id) => {
      console.log(`getTherapistById id=${id} ${typeof id}`);
      const p = state.therapistsList.find((p) => p.th_id === Number(id));
      console.log(`getTherapistById id=${id} ${typeof id}`, p);
      return p;
      // return state.therapistsList.find((p) => p.pa_id === Number(id));
    },
    getTherapistSessions: (state) => (id) => {
      console.log(
        `getTherapistSessions id=${id}, ${typeof id}, state.therapistsList.length`,
        state.therapistsList.length
      );
      if (state.therapistsList.length) {
        const p = state.therapistsList.find((p) => p.th_id === Number(id));
        console.log(
          `getTherapistSessions id=${id} ${typeof id}, session`,
          p.session
        );
        return p && p.sessions ? p.sessions : [];
      } else {
        return [];
      }
    },
    therapistSessionsCount: (state, getters) => (id) => {
      const sessions = getters.getTherapistSessions(id);
      return sessions.length;
    },
    // getPatientSessions: (state) => (id) => {
    //   console.log(
    //     `getPatientSessions id=${id}, ${typeof id}, state.patientsList.length`,
    //     state.patientsList.length
    //   );
    //   if (state.patientsList.length) {
    //     const p = state.patientsList.find((p) => p.pa_id === Number(id));
    //     console.log(
    //       `getPatientSessions id=${id} ${typeof id}, session`,
    //       p.session
    //     );
    //     return p && p.sessions ? p.sessions : [];
    //   } else {
    //     return [];
    //   }
    // },
    therapistsCount(state) {
      return state.therapistsList.length;
    },
    // patientSessionsCount: (state, getters) => (id) => {
    //   const sessions = getters.getPatientSessions(id);
    //   return sessions.length;
    // },
    // inactivePatientsCount(state, getters) {
    //   return getters.inactivePatients.length;
    // },
    // activePatientsCount(state, getters) {
    //   return getters.activePatients.length;
    // },
  },
  mutations: {
    UPDATE_THERAPISTS_LIST(state, therapists) {
      state.therapistsList = therapists;
      console.log("UPDATE_PATIENTS_LIST", therapists.length);
    },
    SET_THERAPIST_SESSIONS(state, { id, sessions }) {
      const found = state.therapistsList.find((p) => p.th_id === id);
      console.log("SET_THERAPIST_SESSIONS found", found);
      if (found) {
        found.sessions = sessions;
        console.log(`SET_THERAPIST_SESSIONS found.sessions`, found.sessions);
      }
    },
    UPDATE_PERSON_REC(state, personObj) {
      console.log("UPDATE_PERSON_REC, personObj", personObj);
      state.personRec = personObj;
      // console.log("UPDATE_PERSON_REC, state.personRec", state.personRec);
    },
    UPDATE_PATIENT_REC(state, patientObj) {
      console.log("UPDATE_PATIENT_REC, patientObj", patientObj);
      state.patientRec = patientObj;
      console.log("UPDATE_PATIENT_REC, state.patientRec", state.patientRec);
    },
    CLEAR_PERSON_PATIENT_REC(state) {
      state.personRec = null;
      state.patientRec = null;
      console.log(
        `CLEAR_PERSON_PATIENT_REC, state.personRec = ${state.personRec}, state.patientRec = ${state.patientRec}`
      );
    },
    CLEAR_STATE(state) {
      state.personRec = null;
      state.patientRec = null;
      state.therapistsList = [];
      console.log(
        `CLEAR_STATE, state.personRec = ${state.personRec}, state.patientRec = ${state.patientRec}`
      );
    },
  },
  actions: {
    async fetchTherapists({ commit }) {
      console.log(
        "fetchTherapists called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.get("/REST/TherapistOverview");
        if (res.status === 200 && res.data) {
          console.log("fetchTherapists res.data", res.data);
          // console.log(
          //   "fetchTherapists UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Therapists",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "fetchTherapists apiRes.data.length",
              apiRes.data.length
            );
            commit("UPDATE_THERAPISTS_LIST", apiRes.data);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Therapists",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchTherapists error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Therapists", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
    async fetchTherapistSessions({ commit }, id) {
      console.log(
        "fetchTherapistSessions called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // http://www.freepascal.org:8989/REST/PatientSessionOverview?ps_therapist_fk=1
        const res = await axiosInstance.get(
          `/REST/PatientSessionOverview?ps_therapist_fk=${id}`
        );

        if (res.status === 200 && res.data) {
          console.log("fetchTherapistSessions res.data", res.data);
          // console.log(
          //   "fetchTherapistSessions UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Therapist Sessions",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "fetchTherapistSessions apiRes.data.length",
              apiRes.data.length
            );
            const sessions = apiRes.data;
            commit("SET_THERAPIST_SESSIONS", { id, sessions });
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Therapist Sessions",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchTherapistSessions error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Therapist Sessions", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
    async fetchPatientRec({ commit }, personId) {
      console.log(
        "fetchPatientRec called personId=${personId}, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // const personId = rootState.user.PersonID;
        const res = await axiosInstance.get(
          `/REST/Patients?pa_person_fk=${personId}`
        );
        if (res.status === 200 && res.data) {
          console.log("fetchPatientRec res.data", res.data);
          // console.log(
          //   "fetchPatientRec UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Patient Data",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data && apiRes.data.length > 0) {
            console.log(
              `fetchPatientRec apiRes.data.length ${apiRes.data.length}`,
              apiRes.data[0]
            );
            commit("UPDATE_PATIENT_REC", apiRes.data[0]);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Patient Data",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchPatientRec e.config = ", e.config);
        console.log("catch fetchPatientRec e.message", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Patient Data", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
    async fetchPersonRec({ commit }, personId) {
      console.log(
        `fetchPersonRec called personId=${personId}, axiosInstance`,
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // const personId = rootState.user.PersonID;
        // const res = await axiosInstance.get(`/REST/Persons?pe_id=${personId}`);
        const res = await axiosInstance.get(`/REST/Persons/${personId}`);
        if (res.status === 200 && res.data) {
          console.log("fetchPersonRec res.data", res.data);
          // console.log(
          //   "fetchPersonRec UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Personal Data",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data && apiRes.data.length > 0) {
            console.log(
              `fetchPersonRec apiRes.data.length ${apiRes.data.length}`,
              apiRes.data[0]
            );
            commit("UPDATE_PERSON_REC", apiRes.data[0]);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Personal Data",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchPersonRec error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Personal Data", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    /** Update patient */
    // async updatePatient(
    //   { commit },
    //   { personsBody, personId, patientsBody, patientId }
    // ) {
    //   console.log(`personId: ${personId}, personsBody`, personsBody);
    //   console.log(`patientId: ${patientId}, patientsBody`, patientsBody);
    //   commit("SET_LOADING", true, { root: true });
    //   // 1. put PersonsTable table
    //   if (Object.keys(personsBody).length) {
    //     await dispatch("updatePersonsTable", {
    //       body: personsBody,
    //       personId: personId,
    //     });
    //     if (rootState.error.title !== null) {
    //       commit("SET_LOADING", false, { root: true });
    //       return;
    //     }
    //     // fetchPersonRec
    //     dispatch("fetchPersonRec", personId);
    //   }
    //   // 2. put PatientsTable table
    //   if (Object.keys(patientsBody).length) {
    //     await dispatch("updatePatientsTable", {
    //       body: patientsBody,
    //       patientId: patientId,
    //     });
    //     if (rootState.error.title !== null) {
    //       commit("SET_LOADING", false, { root: true });
    //       return;
    //     }
    //     // fetchPatientRec
    //     dispatch("fetchPatientRec", personId);
    //   }

    //   // // 3. patients/fetchPatients
    //   // dispatch("patients/fetchPatients", null, { root: true });
    // },
    async updatePatient(
      { commit, dispatch, rootState },
      { personsBody, personId, patientsBody, patientId }
    ) {
      console.log(`personId: ${personId}, personsBody`, personsBody);
      console.log(`patientId: ${patientId}, patientsBody`, patientsBody);
      commit("SET_LOADING", true, { root: true });
      // 1. put PersonsTable table
      if (Object.keys(personsBody).length) {
        await dispatch("updatePersonsTable", {
          body: personsBody,
          personId: personId,
        });
        if (rootState.error.title !== null) {
          commit("SET_LOADING", false, { root: true });
          return;
        }
        // 1.1 fetchPersonRec
        await dispatch("fetchPersonRec", personId);
        // dispatch("fetchPersonRec", personId);
      }
      // 2. put PatientsTable table
      if (Object.keys(patientsBody).length) {
        await dispatch("updatePatientsTable", {
          body: patientsBody,
          patientId: patientId,
        });
        if (rootState.error.title !== null) {
          commit("SET_LOADING", false, { root: true });
          return;
        }
        // 2.1 fetchPatientRec
        await dispatch("fetchPatientRec", personId);
      }
      if (rootState.user.Roles !== "Patient") {
        // 3. patients/fetchPatients
        await dispatch("patients/fetchPatients", null, { root: true });
        // dispatch("patients/fetchPatients", null, { root: true });
      }
    },

    async updatePersonsTable({ commit }, { body, personId }) {
      // commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // const personId = rootState.user.PersonID;
        // const res = await axiosInstance.put(`/REST/Persons/${personId}`, body);
        const res = await axiosInstance.patch(
          `/REST/Persons/${personId}`,
          body
        );
        if (res.status === 200 && res.data) {
          console.log("updatePersonsTable res.data", res.data);
          // console.log(
          //   "updatePersonsTable UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Update Persons Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data && apiRes.data.length > 0) {
            console.log(
              `updatePersonsTable apiRes.data.length ${apiRes.data.length}`,
              apiRes.data[0]
            );
            commit("UPDATE_PATIENT_REC", apiRes.data[0]);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Update Persons Table",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        // console.log("catch updatePersonsTable e", e);
        console.log("catch updatePersonsTable e.message", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Update Persons Table", desp: e.message },
          { root: true }
        );
      }
    },

    async updatePatientsTable({ commit }, { body, patientId }) {
      // commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // const patientId = rootState.user.Patient;
        // const res = await axiosInstance.put(
        //   `/REST/Patients/${patientId}`,
        //   body
        // );
        const res = await axiosInstance.patch(
          `/REST/Patients/${patientId}`,
          body
        );
        if (res.status === 200 && res.data) {
          console.log("updatePatientsTable res.data", res.data);
          // console.log(
          //   "updatePatientsTable UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Update Patients Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data && apiRes.data.length > 0) {
            console.log(
              `updatePatientsTable apiRes.data.length ${apiRes.data.length}`,
              apiRes.data[0]
            );
            commit("UPDATE_PATIENT_REC", apiRes.data[0]);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Update Patients Table",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch updatePatientsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Update Patients Table", desp: e.message },
          { root: true }
        );
      }
    },
  },
};
