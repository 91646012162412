<template>
  <router-view />

  <div class="toast-container position-fixed top-0 end-0 mt-1" style="z-index: 1111">
    <div
      class="toast fade"
      id="errorToast"
      :class="['toast bg-danger', error.title ? 'show' : 'hide']"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header py-1">
        <i class="bi bi-exclamation-triangle-fill me-2 text-danger" style="font-size: 18px;"></i>
        <strong class="me-auto">{{ error.title }}</strong>
        <!-- <small>11 mins ago</small> -->
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click="closeToast"
        ></button>
      </div>
      <!-- <div class="toast-body bg-light">
        {{ error.desp }}
      </div> -->
      <div class="toast-body bg-light" v-html="error.desp"></div>
    </div>

    <div
      class="toast fade"
      id="SuccessToast"
      :class="['toast bg-success', info.title ? 'show' : 'hide']"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header py-1">
        <i class="bi bi-check-circle-fill me-2 text-success" style="font-size: 18px;"></i>
        <strong class="me-auto">{{ info.title }}</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click="closeToastSuccess"
        ></button>
      </div>
      <!-- <div class="toast-body bg-light">
        {{ info.desp }}
      </div> -->
      <div class="toast-body bg-light" v-html="info.desp"></div>
    </div>
  </div>

  <!-- <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1111">
    <div
      id="SuccessToast"
      :class="['toast bg-danger show']"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header py-1">
        <i class="bi bi-exclamation-triangle-fill me-2 text-danger" style="font-size: 18px;"></i>
        <strong class="me-auto">info.title</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click="closeToastSuccess"
        ></button>
      </div>
      <div class="toast-body bg-light">
        test
      </div>
    </div>
  </div> -->
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const { state, commit } = useStore();

    const closeToast = () => {
      console.log("closeToast");
      commit("UPDATE_ERROR", { title: null, desp: null });
    };

    const closeToastSuccess = () => {
      console.log("closeToastSuccess");
      commit("UPDATE_INFO", { title: null, desp: null });
    };

    // watch(state.error, (value) => {
    //   console.log("Watch store.state.error - value", value);
    //   if (value) {
    //     console.log(
    //       "Watch store.state.error - value changed to",
    //       JSON.stringify(value)
    //     );
    //     if (value.title) {
    //       setTimeout(() => {
    //         closeToast();
    //       }, 300000);
    //     }
    //   }
    // });
    // console.log("app setup");
    return {
      closeToast,
      closeToastSuccess,
      error: computed(() => state.error),
      info: computed(() => state.info),
    };
  },
};
</script>
