import { required, email, helpers, minLength } from "@vuelidate/validators";

const MONTH_NAMES = [
  { desp: "January", value: "01" },
  { desp: "February", value: "02" },
  { desp: "March", value: "03" },
  { desp: "April", value: "04" },
  { desp: "May", value: "05" },
  { desp: "June", value: "06" },
  { desp: "July", value: "07" },
  { desp: "August", value: "08" },
  { desp: "September", value: "09" },
  { desp: "October", value: "10" },
  { desp: "November", value: "11" },
  { desp: "December", value: "12" },
];

// const SESSTYPE = [
//   { code: "Live", desp: "Live session", value: 52 },
//   { code: "Video", desp: "Video session", value: 53 },
//   { code: "Telephone", desp: "Telephone session", value: 54 },
// ];

// const NATION = [
//   { code: "BE", desp: "Belgisch", value: 7 },
//   { code: "NL", desp: "Nederlands", value: 8 },
// ];

// const MARITAL = [
//   { code: "MARRIED", desp: "Getrouwd", value: 12 },
//   { code: "SINGLE", desp: "Niet getrouwd", value: 13 },
//   { code: "DIVORCED", desp: "Gescheiden", value: 14 },
// ];

// const EDU = [
//   { code: "SEC", desp: "Secundaire school", value: 15 },
//   { code: "HIGH", desp: "Hogeschool", value: 16 },
//   { code: "BACH", desp: "Bachelor", value: 17 },
//   { code: "MAST", desp: "Master", value: 18 },
// ];

// const PATSTAT_CONF = 9;
// const PATSTAT_ACTIVE = 10;
// const PATSTAT_INACTIVE = 11;

// const PATSTAT = [
//   {
//     code: "CONF",
//     desp: "Bevestiging nodig",
//     value: PATSTAT_CONF,
//     color: "info",
//   },
//   { code: "ACTIVE", desp: "Actief", value: PATSTAT_ACTIVE, color: "primary" },
//   {
//     code: "INACTIVE",
//     desp: "Inactief",
//     value: PATSTAT_INACTIVE,
//     color: "secondary",
//   },
// ];

// const RELTYPE_PATIENT = 49;
// const RELTYPE = [
//   { code: "PATIENT", desp: "Patient", value: RELTYPE_PATIENT },
//   { code: "DOCTOR", desp: "Doctor", value: 50 },
//   { code: "FAMILY", desp: "Relative", value: 51 },
// ];

// const HINS = [
//   { code: "CM", desp: "Christelijke mutualiteiten", value: 19 },
//   { code: "CMV", desp: "Christelijke mutualiteiten vlaanderen", value: 20 },
//   { code: "MC", desp: "Mutualité Chretienne", value: 21 },
//   { code: "UNMN", desp: "Neutrale ziekenfondsen", value: 22 },
//   { code: "VNZ", desp: "Vlaams & neutraal ziekenfonds", value: 23 },
//   { code: "LMN", desp: "La mutualité neutre", value: 24 },
//   { code: "MN", desp: "Mutualia", value: 25 },
//   { code: "NZV", desp: "Neutraal ziekenfonds vlaanderen", value: 26 },
//   { code: "SOLN", desp: "Solidaris (Nationaal)", value: 27 },
//   { code: "SOLA", desp: "Solidaris (Antwerpen)", value: 28 },
//   { code: "SOLB", desp: "Solidaris (Brabant)", value: 29 },
//   { code: "SOLWV", desp: "Solidaris (West-Vlaanderen)", value: 30 },
//   { code: "SOLOV", desp: "Solidaris (Oost-Vlaanderen)", value: 31 },
//   { code: "SOLW", desp: "Solidaris (Wallonie)", value: 32 },
//   { code: "SOLLI", desp: "Solidaris (Limburg)", value: 33 },
//   { code: "SOLLU", desp: "Solidaris (Luxemburg)", value: 34 },
//   { code: "NLM", desp: "Liberale mutualiteiten", value: 35 },
//   { code: "LMA", desp: "Liberale mutualiteiten (Antwerpen)", value: 36 },
//   { code: "LMPL", desp: "LM MUTPLUS", value: 37 },
//   { code: "LMWV", desp: "Liberale mutualiteiten (West-Vlaanderen)", value: 38 },
//   { code: "LMOV", desp: "Liberale mutualiteiten (Oost-Vlaanderen)", value: 39 },
//   { code: "LMNH", desp: "Liberale mutualiteiten (Namur/Hainaut)", value: 40 },
//   { code: "LMLL", desp: "Liberale mutualiteiten (Liège/Luxembourg)", value: 41 },
//   { code: "LMLI", desp: "Liberale mutualiteit plus)", value: 42 },
//   { code: "LOZ", desp: "Onafhankelijke ziekenfondsen", value: 43 },
//   { code: "PART", desp: "Partena", value: 44 },
//   { code: "FREI", desp: "Freie Krankenkasse", value: 45 },
//   { code: "SECU", desp: "Securex", value: 46 },
//   { code: "HELAN", desp: "Helan onafhankelijk ziekenfonds", value: 47 },
//   { code: "?", desp: "Onbekend", value: 55 },
// ];

const PATSTAT_CONF_COLOR = "info";
const PATSTAT_ACTIVE_COLOR = "primary";
const PATSTAT_INACTIVE_COLOR = "secondary";

/** vuelidate functions */
const requiredMsg = helpers.withMessage("Required", required);

const emailMsg = helpers.withMessage("Email is not valid", email);

const phoneNumberRegx = helpers.regex(/^([0-9]|#|\(|\)|\s|-|\+|\*)+$/)
const phoneNumberMsg = helpers.withMessage("Phone number is not valid", phoneNumberRegx);

// const alpha = helpers.regex(/^[a-zA-Z]*$/)

// const isContainsNumber = helpers.regex(/^(?=.*[0-9]).*$/);
// const isNonWhiteSpace = helpers.regex(/^\S*$/)
// const isContainsLetter = helpers.regex(/^(?=.*[a-zA-Z]).*$/);

const isContainsLetter = helpers.withMessage(
  "At least one letter",
  helpers.regex(/^(?=.*[a-zA-Z]).*$/)
);

const isContainsUpperLetter = helpers.withMessage(
  "At least one uppercase letter",
  helpers.regex(/^(?=.*[A-Z]).*$/)
);
const isContainsLowerLetter = helpers.withMessage(
  "At least one lowercase letter",
  helpers.regex(/^(?=.*[a-z]).*$/)
);

const isContainsNumber = helpers.withMessage(
  "At least one number",
  helpers.regex(/^(?=.*[0-9]).*$/)
);

const isNonWhiteSpace = helpers.withMessage(
  "No white space",
  helpers.regex(/^\S*$/)
);

// const minLengthMsg = (d) =>
//   helpers.withMessage(
//     ({ $params }) => `This field must have a min length of ${$params.min}`,
//     minLength(d)
//   );
const minLengthMsg = (d, unit) =>
  helpers.withMessage(`Minimum ${d} ${unit}`, minLength(d));

/** Google file picker API */

const GOOGLE_DRIVE_SCOPES =
  "https://www.googleapis.com/auth/drive.metadata.readonly";

/** anne's File Picker Demo */
/** uncomment these, run python server at http://localhost:8080, and try your gmail login */
// const GOOGLE_DRIVE_CLIENT_ID = '774505215782-ahrfc0ru2sp5f79ea3ej3aoj5d0ecfu0.apps.googleusercontent.com';
// const GOOGLE_DRIVE_API_KEY = 'AIzaSyBkZEj_wFOBOjLTt91hgpiypt9dgCDv5_k';
// const GOOGLE_DRIVE_APP_ID = '774505215782';

/** michael's CotiNotis */
const GOOGLE_DRIVE_CLIENT_ID =
  "673859675281-akccj12j7gevhvsb5n0rg5sduitedjrc.apps.googleusercontent.com";
const GOOGLE_DRIVE_API_KEY = "AIzaSyCwU64Xc-Embd18XfSkNxS9xrae_YBROQw";
const GOOGLE_DRIVE_APP_ID = "673859675281";

export {
  MONTH_NAMES,

  // SESSTYPE,
  // NATION,
  // MARITAL,
  // EDU,
  // PATSTAT_CONF,
  // PATSTAT_ACTIVE,
  // PATSTAT_INACTIVE,
  // PATSTAT,
  // RELTYPE,
  // RELTYPE_PATIENT,
  // HINS,

  PATSTAT_CONF_COLOR,
  PATSTAT_ACTIVE_COLOR,
  PATSTAT_INACTIVE_COLOR,
  requiredMsg,
  emailMsg,
  phoneNumberMsg,
  minLengthMsg,
  isContainsUpperLetter,
  isContainsLowerLetter,
  isContainsNumber,
  isNonWhiteSpace,
  isContainsLetter,
  GOOGLE_DRIVE_SCOPES,
  GOOGLE_DRIVE_CLIENT_ID,
  GOOGLE_DRIVE_API_KEY,
  GOOGLE_DRIVE_APP_ID,
};
