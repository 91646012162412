<template>
  <!-- ======= Header ======= -->
  <header id="header" class="header fixed-top d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center justify-content-between">
      <i class="bi bi-list toggle-sidebar-btn me-2" @click="toggleSidebar"></i>
      <div class="logo d-flex align-items-center">
        <i class="bi bi-file-earmark-medical-fill fs-3 p-2"></i>
        <span class="d-block">Cotinotis</span>
      </div>
      <!-- {{ error }} -->
      <!-- <i class="bi bi-list toggle-sidebar-btn" @click="toggleSidebar"></i> -->
    </div>
    <!-- End Logo -->
    <div class="d-flex align-items-center" >
      <select v-model="$i18n.locale" class="form-select form-select-sm me-5 lang-selection">
        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale.toUpperCase()}}</option>
      </select>
      <nav class="header-nav ms-auto">
        <ul class="d-flex align-items-center">
          <li class="nav-item dropdown pe-3">
            <a
              class="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i class="bi bi-person-circle fs-3"></i>
              <span class="d-none d-sm-block dropdown-toggle ps-2">{{
                user ? user.Naam : ""
              }}</span>
            </a>
            <!-- End Profile Iamge Icon -->

            <ul
              class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"
            >
              <li class="dropdown-header">
                <h6>{{ user ? user.Naam : "" }}</h6>
                <span>{{ user ? user.Roles : "" }}</span>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>

              <li>
                <router-link
                  v-if="isLoginPatient === true"
                  to="/lpEdit"
                  class="dropdown-item d-flex align-items-center"
                >
                  <i class="bi bi-person"></i>
                  <!-- <span>My Profile</span> -->
                  <span>{{ $t("menu.my_profile") }}</span>
                </router-link>
                <router-link
                  v-else
                  to="/therapistProfile"
                  class="dropdown-item d-flex align-items-center"
                >
                  <i class="bi bi-person"></i>
                  <!-- <span>My Profile</span> -->
                  <span>{{ $t("menu.my_profile") }}</span>
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>

              <!-- <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="users-profile.html"
                >
                  <i class="bi bi-gear"></i>
                  <span>Account Settings</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li> -->
              <li>
                <span
                  class="dropdown-item d-flex align-items-center"
                  role="button"
                  @click="$store.dispatch('logout')"
                >
                  <i class="bi bi-box-arrow-right"></i>
                  <span>Log Out</span>
                  <!-- <span>{{ $t("menu.log_out") }}</span> -->
                </span>
              </li>
            </ul>
            <!-- End Profile Dropdown Items -->
          </li>
          <!-- End Profile Nav -->
        </ul>
      </nav>
    </div>
    <!-- End Icons Navigation -->
  </header>
  <!-- End Header -->

  <!-- ======= Sidebar ======= -->
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">
      <!-- if login as therapist -->
      <li v-if="isLoginPatient === false" class="nav-heading">
        <!-- Patients ({{ patientsCount }}) -->
        {{ $t("menu.lbl_patients") }} ({{ patientsCount }})
      </li>
      <li v-if="isLoginPatient === false" class="nav-item active">
        <router-link to="/active" class="nav-link">
          <i class="bi bi-people-fill"></i>
          <!-- <span>Actief<small class="text-muted">({{ activePatientsCount }})</small></span> -->
          <span
            >{{ $t("menu.active") }}
            <small class="text-muted">({{ activePatientsCount }})</small></span
          >
        </router-link>
      </li>
      <li v-if="isLoginPatient === false" class="nav-item">
        <router-link to="/inactive" class="nav-link">
          <i class="bi bi-people"></i>
          <!-- <span>Inactief<small class="text-muted">({{ inactivePatientsCount }})</small></span> -->
          <span
            >{{ $t("menu.inactive") }}
            <small class="text-muted"
              >({{ inactivePatientsCount }})</small
            ></span
          >
        </router-link>
      </li>
      <li v-if="isLoginPatient === false" class="nav-item">
        <router-link to="/new" class="nav-link">
          <i class="bi bi-person-plus"></i>
          <!-- <span>New</span> -->
          <span>{{ $t("menu.new") }}</span>
        </router-link>
      </li>
      <!-- End Patiënten Nav -->

      <!-- (login patient) Threpists Nav -->

      <!-- <li v-if="isLoginPatient === true" class="nav-heading">Overview</li> -->
      <li v-if="isLoginPatient === true" class="nav-heading">{{ $t("menu.lbl_overview") }}</li>
      <li v-if="isLoginPatient === true" class="nav-item">
        <router-link to="/lpTherapists" class="nav-link">
          <i class="bi bi-person-video2"></i>
          <!-- <span>Threpists ({{ therapistsCount }})</span> -->
          <span>{{ $t("menu.threpists") }} ({{ therapistsCount }})</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link
          v-if="isLoginPatient === true"
          to="/lpEdit"
          class="nav-link"
        >
          <i class="bi bi-person"></i>
          <span>My Profile</span>
        </router-link>
        <router-link v-else to="/therapistProfile" class="nav-link">
          <i class="bi bi-person"></i>
          <span>My Profile</span>
        </router-link>
      </li> -->

      <!-- <li class="nav-heading">Account</li> -->
      <li class="nav-heading">{{ $t("menu.lbl_account") }}</li>
      <li class="nav-item">
        <router-link
          v-if="isLoginPatient === true"
          to="/lpEdit"
          class="nav-link"
        >
          <i class="bi bi-person"></i>
          <!-- <span>My Profile</span> -->
          <span>{{ $t("menu.my_profile") }}</span>
        </router-link>
        <router-link v-else to="/therapistProfile" class="nav-link">
          <i class="bi bi-person-fill"></i>
          <!-- <span>My Profile</span> -->
          <span>{{ $t("menu.my_profile") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/modifyPassword" class="nav-link">
          <i class="bi bi-eye-slash-fill"></i>
          <!-- <span>Password</span> -->
          <span>{{ $t("menu.password") }}</span>
        </router-link>
      </li>

      <li class="nav-item" @click="$store.dispatch('logout')">
        <a class="nav-link collapsed" href="#">
          <i class="bi bi-box-arrow-right"></i>
          <span>Log Out</span>
          <!-- <span>{{ $t("menu.log_out") }}</span> -->
        </a>
      </li>
      <!-- End logout Page Nav -->
    </ul>
  </aside>
  <!-- End Sidebar-->

  <div style="min-height: 90vh">
    <router-view></router-view>
  </div>

  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
    <div class="copyright">
      &copy; Copyright {{ new Date().getFullYear() }}
      <strong><span>Cotinotis</span></strong
      >. All Rights Reserved
    </div>
    <div class="credits">Created by Michael Van Canneyt</div>
  </footer>
  <!-- End Footer -->

  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>

  <!-- <v-idle
    @idle="onidle"
    @remind="onremind"
    :loop="true"
    :reminders="[10, 15]"
    :wait="5"
    :duration="300"
  /> -->
  <!-- <v-idle @idle="onidle" :duration="1800000" /> -->
  <!-- <v-idle @idle="onidle" :duration="10" /> -->
  <v-idle @idle="onidle" :duration="18000" />

  <!-- <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div
      id="liveToast"
      class="toast"
      :class="error.title ? 'show' : 'hide'"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        
        <strong class="me-auto">{{ error.title }}</strong>
        
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">{{ error.desp }}</div>
    </div>
  </div> -->
</template>

<script>
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "HomeView",
  setup() {
    const { state, commit, dispatch, getters } = useStore();
    // const router = useRouter();

    const toggleSidebar = () => {
      document.querySelector("body").classList.toggle("toggle-sidebar");
    };

    const onidle = () => {
      dispatch("logout");
      commit("UPDATE_INFO", {
        title: "Logout",
        desp: "Session ended due to no activity within 30 minutes.",
      });
    };

    // const isLoginPatient = computed(() => state.user.Roles === "Patient");

    const isLoginPatient = computed(() => {
      if (state.user) {
        return state.user.Roles === "Patient";
      } else {
        return null;
      }
    });

    // const onremind = (time) => {
    //   alert(time);
    // };
    return {
      toggleSidebar,
      onidle,
      // onremind,
      isLoginPatient,
      user: computed(() => state.user),
      error: computed(() => state.error),
      patientsCount: computed(() => getters["patients/patientsCount"]),
      inactivePatientsCount: computed(
        () => getters["patients/inactivePatientsCount"]
      ),
      activePatientsCount: computed(
        () => getters["patients/activePatientsCount"]
      ),
      therapistsCount: computed(() => getters["loginPatient/therapistsCount"]),
    };
  },
};
</script>
