// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      guest: true,
    },
    component: LoginView,
  },
  {
    path: "/signup",
    name: "Signup",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "SignupView" */ "../views/SignupView.vue"),
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "ForgotPassword" */ "../views/ForgotPassword.vue"),
  },
  {
    path: "/changePassword/:token",
    name: "ChangePassword",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "ChangePassword" */ "../views/ChangePassword.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/active",
        name: "ActivePatients",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "ActivePatients" */ "../views/ActivePatients.vue"),
      },
      {
        path: "/inactive",
        name: "InactivePatients",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "InactivePatients" */ "../views/InactivePatients.vue"
          ),
      },
      {
        path: "/new",
        name: "NewPatient",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "NewPatientView" */ "../views/NewPatientView.vue"),
      },
      {
        path: "/edit/:id",
        name: "Edit",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "EditPatientView" */ "../views/EditPatientView.vue"
          ),
        children: [
          {
            path: "",
            name: "PatientSessions",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "PatientSessionsView" */ "../views/PatientSessionsView.vue"
              ),
          },
          {
            path: "session/:sid",
            name: "EditPatientSession",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "EditPatientSessionView" */ "../views/EditPatientSessionView.vue"
              ),
          },
        ],
      },
      {
        path: "/therapistProfile",
        name: "TherapistProfile",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "TherapistProfile" */ "../views/TherapistProfile.vue"
          ),
      },
      {
        path: "/modifyPassword",
        name: "ModifyPassword",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ModifyPasswordView" */ "../views/ModifyPasswordView.vue"
          ),
      },
      {
        path: "/lpTherapists",
        name: "LoginPatientTherapists",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "LoginPatientTherapists" */ "../views/LoginPatientTherapists.vue"
          ),
      },
      {
        path: "/lpTherapists/:id",
        name: "LoginPatientTherapistSessions",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "LoginPatientTherapistSessions" */ "../views/LoginPatientTherapistSessions.vue"
          ),
      },
      {
        path: "/lpEdit",
        name: "LoginPatientEdit",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "LoginPatientEdit" */ "../views/LoginPatientEdit.vue"
          ),
      },
      {
        path: "/:pathMatch(.*)*",
        component: () =>
          import(/* webpackChunkName: "Error404View" */ "../views/Error404View.vue"),
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(`beforeEach - store.state.user = ${store.state.user}, to`, to);
  // console.log("beforeEach from", JSON.stringify(from));
  // if (to.name !== "Login") {
  store.commit("UPDATE_ERROR", { title: null, desp: null });
  // }
  // store.dispatch("tryAutoSignIn");
  // store.commit("CLEAR_ERROR_INFO");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.log("beforeEach - call tryAutoSignIn");
    store
      .dispatch("tryAutoSignIn")
      .then(() => {
        console.log("beforeEach - tryAutoSignIn returns");
        if (!store.state.user) {
          console.log(
            "beforeEach next to Login! store.state.user",
            store.state.user
          );
          next({ name: "Login" });
        } else {
          console.log("beforeEach next()! store.state.user", store.state.user);
          next(); // go to wherever I'm going
        }
      })
      .catch((err) => {
        console.log("beforeEach - tryAutoSignIn err, redirect to login", err);
        next({ name: "Login" });
      });

    // if (!store.state.user) {
    //   console.log(
    //     "beforeEach next to Login! store.state.user",
    //     store.state.user
    //   );
    //   next({ name: "Login" });
    // } else {
    //   console.log("beforeEach next()! store.state.user", store.state.user);
    //   next(); // go to wherever I'm going
    // }
  } else {
    console.log("beforeEach next()!!");
    next(); // does not require auth, make sure to always call next()!
  }
  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   if (!store.state.user) {
  //     console.log("beforeEach go to Login!");
  //     next({ name: "Login" });
  //   } else {
  //     console.log("beforeEach next()1");
  //     next();
  //   }
  // } else if (to.matched.some((record) => record.meta.onlyGuest)) {
  //   if (store.state.user) {
  //     console.log("beforeEach go to Home!");
  //     next({ name: "Home" });
  //   } else {
  //     console.log("beforeEach next()2");
  //     next();
  //   }
  // } else {
  //   console.log("beforeEach next()3");
  //   next();
  // }
});

export default router;
