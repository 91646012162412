import { axiosInstance } from "@/plugins/axios";
// import { RELTYPE_PATIENT, PATSTAT_CONF } from "@/shared/globals";

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  namespaced: true,
  state: {
    patientsList: [],
    addPatientPersonId: null,
    therapistPersonRec: null, // store login therapist profile
    // patientRec: null,
  },
  getters: {
    inactivePatients(state) {
      return state.patientsList.filter(
        (patient) => patient.pa_statuscode === "INACTIVE"
      );
    },
    activePatients(state) {
      return state.patientsList.filter(
        (patient) => patient.pa_statuscode !== "INACTIVE"
      );
    },

    /** Patient Count */
    patientsCount(state) {
      return state.patientsList.length;
    },
    inactivePatientsCount(state, getters) {
      return getters.inactivePatients.length;
    },
    activePatientsCount(state, getters) {
      return getters.activePatients.length;
    },
    getPatientById: (state) => (id) => {
      console.log(`getPatientById id=${id} ${typeof id}`);
      const p = state.patientsList.find((p) => p.pa_id === Number(id));
      console.log(`getPatientById id=${id} ${typeof id}`, p);
      return p;
      // return state.patientsList.find((p) => p.pa_id === Number(id));
    },

    /** Patient Sessions */
    getPatientSessions: (state) => (id) => {
      console.log(
        `getPatientSessions id=${id}, ${typeof id}, state.patientsList.length`,
        state.patientsList.length
      );
      if (state.patientsList.length) {
        const p = state.patientsList.find((p) => p.pa_id === Number(id));
        console.log(
          `getPatientSessions id=${id} ${typeof id}, sessions`,
          p.sessions
        );
        return p && p.sessions ? p.sessions : [];
      } else {
        return [];
      }
    },
    patientSessionsCount: (state, getters) => (id) => {
      const sessions = getters.getPatientSessions(id);
      return sessions.length;
    },

    /** Patient Contacts */
    getPatientContacts: (state) => (id) => {
      console.log(
        `getPatientContacts id=${id}, ${typeof id}, state.patientsList.length`,
        state.patientsList.length
      );
      if (state.patientsList.length) {
        const p = state.patientsList.find((p) => p.pa_id === Number(id));
        console.log(
          `getPatientContacts id=${id} ${typeof id}, contacts`,
          p.contacts
        );
        return p && p.contacts ? p.contacts : [];
      } else {
        return [];
      }
    },
    patientContactsCount: (state, getters) => (id) => {
      const contacts = getters.getPatientContacts(id);
      return contacts.length;
    },

    /** Patient Documents */
    getPatientDocuments: (state) => (id) => {
      console.log(
        `getPatientDocuments id=${id}, ${typeof id}, state.patientsList.length`,
        state.patientsList.length
      );
      if (state.patientsList.length) {
        const p = state.patientsList.find((p) => p.pa_id === Number(id));
        console.log(
          `getPatientDocuments id=${id} ${typeof id}, documents`,
          p.documents
        );
        return p && p.documents ? p.documents : [];
      } else {
        return [];
      }
    },
    patientDocumentsCount: (state, getters) => (id) => {
      const documents = getters.getPatientDocuments(id);
      return documents.length;
    },
  },
  mutations: {
    // addPatient(state, patient) {
    //   state.patientsList.unshift(patient);
    // },
    UPDATE_PATIENTS_LIST(state, patients) {
      state.patientsList = patients;
      console.log("UPDATE_PATIENTS_LIST", patients.length);
    },
    SET_PATIENT_SESSIONS(state, { patientId, patientSessions }) {
      const found = state.patientsList.find((p) => p.pa_id === patientId);
      console.log("SET_PATIENT_SESSIONS found", found);
      if (found) {
        found.sessions = patientSessions;
        console.log(`SET_PATIENT_SESSIONS found.sessions`, found.sessions);
      }
    },
    SET_PATIENT_CONTACTS(state, { personId, patientContacts }) {
      const found = state.patientsList.find((p) => p.pe_id === personId);
      console.log("SET_PATIENT_CONTACTS found", found);
      if (found) {
        found.contacts = patientContacts;
        console.log(`SET_PATIENT_CONTACTS found.contacts`, found.contacts);
      }
    },
    SET_PATIENT_DOCUMENTS(state, { personId, patientDocuments }) {
      const found = state.patientsList.find((p) => p.pe_id === personId);
      console.log("SET_PATIENT_DOCUMENTS found", found);
      if (found) {
        found.documents = patientDocuments;
        console.log(`SET_PATIENT_DOCUMENTS found.documents`, found.documents);
      }
    },
    SET_ADDPATIENT_PERSON_ID(state, id) {
      state.addPatientPersonId = id;
    },
    UPDATE_THERAPIST_PERSON_REC(state, personObj) {
      console.log("UPDATE_THERAPIST_PERSON_REC, personObj", personObj);
      state.therapistPersonRec = personObj;
      // console.log("UPDATE_THERAPIST_PERSON_REC, state.therapistPersonRec", state.therapistPersonRec);
    },
    CLEAR_STATE(state) {
      state.addPatientPersonId = null;
      state.patientsList = [];
      state.therapistPersonRec = null;
      console.log(
        `CLEAR_STATE, state.addPatientPersonId = ${state.addPatientPersonId}, state.patientsList = ${state.patientsList}`
      );
    },
  },
  actions: {
    async fetchPatients({ commit }) {
      console.log(
        "fetchPatients called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });

      // const result = await fetch("/patients.json");
      // const json = await result.json();
      // commit("UPDATE_PATIENTS_LIST", json);
      // await sleep(1000);
      try {
        const res = await axiosInstance.get("/REST/PatientOverview");
        if (res.status === 200 && res.data) {
          console.log("fetchPatients res.data", res.data);
          // console.log(
          //   "fetchPatients UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Patients",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log("fetchPatients apiRes.data.length", apiRes.data.length);
            commit("UPDATE_PATIENTS_LIST", apiRes.data);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Patients",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchPatients error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Patients", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
    async fetchPatientSessions({ commit }, patientId) {
      console.log(
        "fetchPatientSessions called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // http://www.freepascal.org:8989/REST/SessionOverview?ps_patient_fk=1201
        // const res = await axiosInstance.get(
        //   `/REST/SessionOverview?ps_patient_fk=${patientId}`
        // );
        const res = await axiosInstance.get(
          `/REST/TherapistSessionOverview?ps_patient_fk=${patientId}`
        );

        if (res.status === 200 && res.data) {
          console.log("fetchPatientSessions res.data", res.data);
          // console.log(
          //   "fetchPatientSessions UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Patient Sessions",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "fetchPatientSessions apiRes.data.length",
              apiRes.data.length
            );
            const patientSessions = apiRes.data;
            commit("SET_PATIENT_SESSIONS", { patientId, patientSessions });
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Patient Sessions",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchPatientSessions error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Patient Sessions", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async fetchPatientContacts({ commit }, personId) {
      console.log(
        "fetchPatientContacts called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // http://www.freepascal.org:8989/REST/Contacts?pe_id=1038
        const res = await axiosInstance.get(
          `/REST/Contacts?pe_id=${personId}`
        );

        if (res.status === 200 && res.data) {
          console.log("fetchPatientContacts res.data", res.data);
          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Patient Contacts",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "fetchPatientContacts apiRes.data.length",
              apiRes.data.length
            );
            const patientContacts = apiRes.data;
            commit("SET_PATIENT_CONTACTS", { personId, patientContacts });
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Patient Contacts",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchPatientContacts error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Patient Contacts", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async fetchPatientDocuments({ commit }, personId) {
      console.log(
        "fetchPatientDocuments called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.get(
          `/REST/Documents?do_subject_fk=${personId}`
        );

        if (res.status === 200 && res.data) {
          console.log("fetchPatientDocuments res.data", res.data);
          // console.log(
          //   "fetchPatientDocuments UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Patient Documents",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "fetchPatientDocuments apiRes.data.length",
              apiRes.data.length
            );
            const patientDocuments = apiRes.data;
            commit("SET_PATIENT_DOCUMENTS", { personId, patientDocuments });
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Patient Documents",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchPatientDocuments error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Patient Documents", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async fetchTherapistPersonRec({ commit }, personId) {
      console.log(
        `fetchTherapistPersonRec called personId=${personId}, axiosInstance`,
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.get(`/REST/Persons/${personId}`);
        if (res.status === 200 && res.data) {
          console.log("fetchTherapistPersonRec res.data", res.data);
          // console.log(
          //   "fetchTherapistPersonRec UPDATE_AUTH_TOKEN",
          //   res.headers["x-user-token"]
          // );
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Therapist Personal Data",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data && apiRes.data.length > 0) {
            console.log(
              `fetchTherapistPersonRec apiRes.data.length ${apiRes.data.length}`,
              apiRes.data[0]
            );
            commit("UPDATE_THERAPIST_PERSON_REC", apiRes.data[0]);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Therapist Personal Data",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchTherapistPersonRec error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Therapist Personal Data", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async checkTableUniqueField({ commit }, { table, field, value }) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      let found = null;
      try {
        const res = await axiosInstance.get(`/REST/${table}?${field}=${value}`);
        if (res.data) {
          console.log("checkTableUniqueField res.data", res.data);
          const apiRes = res.data;
          if (apiRes.data.length === 0) {
            found = false;
          } else {
            found = true;
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: `Check ${table} ${field}`,
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch checkTableUniqueField error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: `Check ${table} ${field}`, desp: e.message },
          { root: true }
        );
      }
      return found;
    },

    /** Update therapist profile */
    async updateTherapist(
      { commit, dispatch, rootState },
      { personsBody, personId }
    ) {
      console.log(`personId: ${personId}, personsBody`, personsBody);
      commit("SET_LOADING", true, { root: true });
      // 1. put PersonsTable table
      if (Object.keys(personsBody).length) {
        await dispatch(
          "loginPatient/updatePersonsTable",
          {
            body: personsBody,
            personId: personId,
          },
          { root: true }
        );

        if (rootState.error.title !== null) {
          commit("SET_LOADING", false, { root: true });
          return;
        }
        // 1.1 fetchTherapistPersonRec
        await dispatch("fetchTherapistPersonRec", personId);
      }
    },

    /** Add patient */
    async addPatient(
      { state, commit, dispatch, rootState, rootGetters },
      { formPerson, formPatient }
    ) {
      console.log("formPatient", formPatient);
      console.log("formPatient", formPatient, formPerson);
      commit("SET_LOADING", true, { root: true });
      // 1. post persons table
      await dispatch("postPersonsTable", formPerson);
      if (rootState.error.title !== null || state.addPatientPersonId === null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }
      formPatient.pa_person_fk = state.addPatientPersonId;
      // formPatient.pa_status_fk = PATSTAT_CONF;
      formPatient.pa_status_fk = rootGetters['params/PATSTAT_CONF'];
      // 2. post patients table
      await dispatch("postPatientsTable", formPatient);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }
      // 3. post users table
      const formUser = {
        us_username: formPerson.pe_email,
        us_person_fk: state.addPatientPersonId,
        us_password: "",
      };
      await dispatch("postUsersTable", formUser);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }
      // 4. post person relations table
      const formRelation = {
        pr_sourceperson_fk: state.addPatientPersonId,
        pr_targetperson_fk: rootState.user.PersonID,
        // pr_typerelation_fk: RELTYPE_PATIENT,
        pr_typerelation_fk: rootGetters['params/RELTYPE_PATIENT']
      };
      await dispatch("postPersonRelationsTable", formRelation);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }

      // 5 fetchPatients
      dispatch("fetchPatients");
    },
    async postPersonsTable({ commit }, obj) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      commit("SET_ADDPATIENT_PERSON_ID", null);
      try {
        const res = await axiosInstance.post("/REST/persons", obj);
        if (res.status === 201 && res.data) {
          console.log("postPersonsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post Persons Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "postPersonsTable apiRes.data.length",
              apiRes.data.length
            );
            commit("SET_ADDPATIENT_PERSON_ID", apiRes.data[0].pe_id);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post Persons Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch postPersonsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Post Persons Table", desp: e.message },
          { root: true }
        );
      }
    },
    async postPatientsTable({ commit }, obj) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/REST/patients", obj);
        if (res.status === 201 && res.data) {
          console.log("postPatientsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post Patients Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "postPatientsTable apiRes.data.length",
              apiRes.data.length
            );
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post Patients Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch postPatientsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Post Patients Table", desp: e.message },
          { root: true }
        );
      }
    },
    async postUsersTable({ commit }, obj) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/REST/users", obj);
        if (res.status === 201 && res.data) {
          console.log("postUsersTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post Users Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "postUsersTable apiRes.data.length",
              apiRes.data.length
            );
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post Users Table",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch postUsersTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Post Users Table", desp: e.message },
          { root: true }
        );
      }
    },
    async postPersonRelationsTable({ commit }, obj) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/REST/PersonRelations", obj);
        if (res.status === 201 && res.data) {
          console.log("postPersonRelationsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post Person Relations Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "postPersonRelationsTable apiRes.data.length",
              apiRes.data.length
            );
            // commit("SET_ADDPATIENT_PERSON_ID", apiRes.data.pe_id);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post Person Relations Table",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch postPersonRelationsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Post Person Relations Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Delete patient */
    async deletePatient({ commit, dispatch, rootState }, patientId) {
      console.log("deletePatient", patientId);
      commit("SET_LOADING", true, { root: true });
      // 1. Delete Patient RPC call
      await dispatch("deletePatientRpc", patientId);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }
      // 2. fetchPatients
      dispatch("fetchPatients");
    },
    async deletePatientRpc({ commit }, patientId) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/RPC", {
          jsonrpc: "2.0",
          id: "23",
          class: "Patient",
          method: "UnlinkPatient",
          params: [patientId],
        });
        if (res.status === 200 && res.data) {
          console.log("deletePatientRpc res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Delete Patient RPC",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.result === false) {
            console.log(
              "deletePatientRpc failed! apiRes.result",
              apiRes.result
            );
            commit(
              "UPDATE_ERROR",
              {
                title: "Delete Patient RPC",
                desp: `Delete Patient failed (result: ${apiRes.result})`,
              },
              { root: true }
            );
          } else if (apiRes.result === true) {
            console.log("deletePatientRpc OK! apiRes.result", apiRes.result);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Delete Patient RPC",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch deletePatientRpc error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Delete Patient RPC", desp: e.message },
          { root: true }
        );
      }
    },

    /** Add session */
    async addPatientSession(
      { commit, dispatch, rootState },
      { sessionBody, patientId }
    ) {
      console.log("sessionBody", sessionBody);
      commit("SET_LOADING", true, { root: true });
      // 1. post PatientSessions table
      const result = await dispatch("postPatientSessionsTable", sessionBody);
      console.log("addPatientSession result", result);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return null;
      }

      // 2. fetchPatientSessions
      dispatch("fetchPatientSessions", patientId);
      return result.ps_id ? result.ps_id : null;
    },
    async postPatientSessionsTable({ commit }, obj) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/REST/PatientSessions", obj);
        if (res.status === 201 && res.data) {
          console.log("postPatientSessionsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post PatientSessions Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data.length > 0) {
            console.log(
              `postPatientSessionsTable apiRes.data.length ${apiRes.data.length}, apiRes.data[0].ps_id`,
              apiRes.data[0].ps_id
            );
            if (apiRes.data[0].ps_id) {
              return { ps_id: apiRes.data[0].ps_id };
            }
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post PatientSessions Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch postPatientSessionsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Post PatientSessions Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Update session */
    async updatePatientSession(
      { commit, dispatch, rootState },
      { sessionBody, patientId, sessionId }
    ) {
      console.log("sessionBody", sessionBody);
      commit("SET_LOADING", true, { root: true });
      // 1. put PatientSessions table
      await dispatch("putPatientSessionsTable", {
        obj: sessionBody,
        id: sessionId,
      });
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }

      // 2. fetchPatientSessions
      dispatch("fetchPatientSessions", patientId);
    },
    async putPatientSessionsTable({ commit }, { obj, id }) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // const res = await axiosInstance.put(`/REST/PatientSessions/${id}`, obj);
        const res = await axiosInstance.patch(
          `/REST/PatientSessions/${id}`,
          obj
        );
        if (res.status === 200 && res.data) {
          console.log("putPatientSessionsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Put PatientSessions Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "putPatientSessionsTable apiRes.data.length",
              apiRes.data.length
            );
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Put PatientSessions Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch putPatientSessionsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Put PatientSessions Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Delete session */
    async deletePatientSession(
      { commit, dispatch, rootState },
      { deleteSessionId, patientId }
    ) {
      commit("SET_LOADING", true, { root: true });
      // 1. delete PatientSessions table
      await dispatch("deletePatientSessionsTable", deleteSessionId);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }

      // 2. fetchPatientSessions
      dispatch("fetchPatientSessions", patientId);
    },
    async deletePatientSessionsTable({ commit }, id) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.delete(`/REST/PatientSessions/${id}`);
        if (res.status === 204) {
          console.log("deletePatientSessionsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Delete PatientSessions Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else {
            console.log("deletePatientSessionsTable apiRes", apiRes);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Delete PatientSessions Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch deletePatientSessionsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Delete PatientSessions Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Add contact */
    async addPatientContact(
      { commit, dispatch, rootState },
      { body, personId }
    ) {
      console.log("addPatientContact > body", body);
      commit("SET_LOADING", true, { root: true });
      // 1. post PatientSessions table
      const result = await dispatch("postPatientContactsTable", body);
      console.log("addPatientContact result", result);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return null;
      }

      // 2. fetchPatientContacts
      dispatch("fetchPatientContacts", personId);
      return result.pr_id ? result.pr_id : null;
    },
    async postPatientContactsTable({ commit }, obj) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/REST/Contacts", obj);
        if (res.status === 201 && res.data) {
          console.log("postPatientContactsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post Patient Contacts Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data.length > 0) {
            console.log(
              `postPatientContactsTable apiRes.data.length ${apiRes.data.length}, apiRes.data[0].pr_id`,
              apiRes.data[0].pr_id
            );
            if (apiRes.data[0].pr_id) {
              return { pr_id: apiRes.data[0].pr_id };
            }
          } else { // empty data[]
            return { pr_id: null };
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post Patient Contacts Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch postPatientContactsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Post Patient Contacts Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Update contact */
    async updatePatientContact(
      { commit, dispatch, rootState },
      { body, personId, contactId }
    ) {
      console.log("updatePatientContact > body", body);
      commit("SET_LOADING", true, { root: true });
      // 1. put PatientSessions table
      await dispatch("putPatientContactsTable", {
        obj: body,
        id: contactId,
      });
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }

      // 2. fetchPatientContacts
      dispatch("fetchPatientContacts", personId);
    },
    async putPatientContactsTable({ commit }, { obj, id }) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.put(`/REST/Contacts/${id}`, obj);
        // const res = await axiosInstance.patch(
        //   `/REST/PatientSessions/${id}`,
        //   obj
        // );
        if (res.status === 200 && res.data) {
          console.log("putPatientContactsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Put Patient Contacts Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "putPatientContactsTable apiRes.data.length",
              apiRes.data.length
            );
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Put Patient Contacts Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch putPatientContactsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Put Patient Contacts Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Delete contact */
    async deletePatientContact(
      { commit, dispatch, rootState },
      { deleteContactId, personId }
    ) {
      commit("SET_LOADING", true, { root: true });
      // 1. delete PatientContacts table
      await dispatch("deletePatientContactsTable", deleteContactId);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }

      // 2. fetchPatientContacts
      dispatch("fetchPatientContacts", personId);
    },
    async deletePatientContactsTable({ commit }, id) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        // DELETE http://www.freepascal.org:8989/REST/Contacts/pr_id
        const res = await axiosInstance.delete(`/REST/Contacts/${id}`);
        if (res.status === 204) {
          console.log("deletePatientContactsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Delete PatientContacts Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else {
            console.log("deletePatientContactsTable apiRes", apiRes);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Delete PatientContacts Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch deletePatientContactsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Delete PatientContacts Table", desp: e.message },
          { root: true }
        );
      }
    },

    /** Add document */
    async addPatientDocuments(
      { commit, dispatch, rootState },
      { selectedFiles, personId }
    ) {
      commit("SET_LOADING", true, { root: true });
      // 1. add documents table
      for (const obj of selectedFiles) {
        await dispatch("addDocumentsTable", obj);
        if (rootState.error.title !== null) {
          commit("SET_LOADING", false, { root: true });
          return;
        }
      }
      // 2. fetchPatientDocuments
      dispatch("fetchPatientDocuments", personId);
    },
    async addDocumentsTable({ commit }, obj) {
      console.log("addDocumentsTable, obj", obj);
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.post("/REST/documents", obj);
        console.log("addDocumentsTable res", res);
        if (res.status === 201 && res.data) {
          console.log("addDocumentsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Post Documents Table",
                desp: `${obj.do_title} - ${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log(
              "addDocumentsTable apiRes.data.length",
              apiRes.data.length
            );
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Post Documents Table",
              desp: `${obj.do_title} - Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch addDocumentsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          {
            title: "Post Documents Table",
            desp: `${obj.do_title} - ${e.message}`,
          },
          { root: true }
        );
      }
    },

    /** Delete document */
    async deletePatientDocument(
      { commit, dispatch, rootState },
      { deleteDocumentId, personId }
    ) {
      commit("SET_LOADING", true, { root: true });
      // 1. delete documents table
      await dispatch("deleteDocumentsTable", deleteDocumentId);
      if (rootState.error.title !== null) {
        commit("SET_LOADING", false, { root: true });
        return;
      }

      // 2. fetchPatientDocuments
      dispatch("fetchPatientDocuments", personId);
    },
    async deleteDocumentsTable({ commit }, id) {
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });
      try {
        const res = await axiosInstance.delete(`/REST/Documents/${id}`);
        if (res.status === 204) {
          console.log("deleteDocumentsTable res.data", res.data);
          // const token = res.headers["x-user-token"];
          // commit("UPDATE_AUTH_TOKEN", token, { root: true });

          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Delete Documents Table",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else {
            console.log("deleteDocumentsTable apiRes", apiRes);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Delete Documents Table",
              desp: `Invalid response (${res.status})`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch deleteDocumentsTable error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Delete Documents Table", desp: e.message },
          { root: true }
        );
      }
    },
  },
};
