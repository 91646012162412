import { axiosInstance } from "@/plugins/axios";

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  namespaced: true,
  state: {
    EDU: [],
    HINS: [],
    MARITAL: [],
    NATION: [],
    PATSTAT: [],
    RELTYPE: [],
    SESSTYPE: [],
  },
  getters: {
    PATSTAT_CONF(state) {
      const found = state.PATSTAT.find((element) => element.code === 'CONF');
      return found ? found.value : 0
    },
    PATSTAT_ACTIVE(state) {
      const found = state.PATSTAT.find((element) => element.code === 'ACTIVE');
      return found ? found.value : 0
    },
    PATSTAT_INACTIVE(state) {
      const found = state.PATSTAT.find((element) => element.code === 'INACTIVE');
      return found ? found.value : 0
    },
    RELTYPE_PATIENT(state) {
      const found = state.RELTYPE.find((element) => element.code === 'PATIENT');
      return found ? found.value : 0
    },
  },
  mutations: {
    SAVE_PARAMS_DATA(state, apiData) {
      let pmtype
      // let obj = { code: "", desp: "", value: 0 }
      apiData.forEach(entry => {
        pmtype = entry.pm_type;
        if (state[pmtype]) {
          // obj.code = entry.pm_code;
          // obj.desp = entry.pm_description;
          // obj.value = entry.pm_id;
          state[pmtype].push({
            code: entry.pm_code,
            desp: entry.pm_description,
            value: entry.pm_id
          })
        }
      })
      console.log('SAVE_PARAMS_DATA, state', JSON.stringify(state));
    },
    /** EDU = [
      { code: "SEC", desp: "Secundaire school", value: 15 },
      { code: "HIGH", desp: "Hogeschool", value: 16 },
      { code: "BACH", desp: "Bachelor", value: 17 },
      { code: "MAST", desp: "Master", value: 18 },
    ]; */
    UPDATE_EDU(state, payload) {
      state.EDU = payload;
      console.log("UPDATE_EDU", payload);
    },
    /** const HINS = [
      { code: "CM", desp: "Christelijke mutualiteiten", value: 19 },
      { code: "CMV", desp: "Christelijke mutualiteiten vlaanderen", value: 20 },
      { code: "MC", desp: "Mutualité Chretienne", value: 21 },
      { code: "UNMN", desp: "Neutrale ziekenfondsen", value: 22 },
      { code: "VNZ", desp: "Vlaams & neutraal ziekenfonds", value: 23 },
      { code: "LMN", desp: "La mutualité neutre", value: 24 },
      { code: "MN", desp: "Mutualia", value: 25 },
      { code: "NZV", desp: "Neutraal ziekenfonds vlaanderen", value: 26 },
      { code: "SOLN", desp: "Solidaris (Nationaal)", value: 27 },
      { code: "SOLA", desp: "Solidaris (Antwerpen)", value: 28 },
      { code: "SOLB", desp: "Solidaris (Brabant)", value: 29 },
      { code: "SOLWV", desp: "Solidaris (West-Vlaanderen)", value: 30 },
      { code: "SOLOV", desp: "Solidaris (Oost-Vlaanderen)", value: 31 },
      { code: "SOLW", desp: "Solidaris (Wallonie)", value: 32 },
      { code: "SOLLI", desp: "Solidaris (Limburg)", value: 33 },
      { code: "SOLLU", desp: "Solidaris (Luxemburg)", value: 34 },
      { code: "NLM", desp: "Liberale mutualiteiten", value: 35 },
      { code: "LMA", desp: "Liberale mutualiteiten (Antwerpen)", value: 36 },
      { code: "LMPL", desp: "LM MUTPLUS", value: 37 },
      { code: "LMWV", desp: "Liberale mutualiteiten (West-Vlaanderen)", value: 38 },
      { code: "LMOV", desp: "Liberale mutualiteiten (Oost-Vlaanderen)", value: 39 },
      { code: "LMNH", desp: "Liberale mutualiteiten (Namur/Hainaut)", value: 40 },
      { code: "LMLL", desp: "Liberale mutualiteiten (Liège/Luxembourg)", value: 41 },
      { code: "LMLI", desp: "Liberale mutualiteit plus)", value: 42 },
      { code: "LOZ", desp: "Onafhankelijke ziekenfondsen", value: 43 },
      { code: "PART", desp: "Partena", value: 44 },
      { code: "FREI", desp: "Freie Krankenkasse", value: 45 },
      { code: "SECU", desp: "Securex", value: 46 },
      { code: "HELAN", desp: "Helan onafhankelijk ziekenfonds", value: 47 },
      { code: "?", desp: "Onbekend", value: 55 },
    ]; */
    UPDATE_HINS(state, payload) {
      state.HINS = payload;
      console.log("UPDATE_HINS", payload);
    },
    /** MARITAL = [
      { value: "MARRIED", desp: "Getrouwd", id: 12 },
      { value: "SINGLE", desp: "Niet getrouwd", id: 13 },
      { value: "DIVORCED", desp: "Gescheiden", id: 14 },
    ]; */
    UPDATE_MARITAL(state, payload) {
      state.MARITAL = payload;
      console.log("UPDATE_MARITAL", payload);
    },
    /** NATION = [
      { code: "BE", desp: "Belgisch", value: 7 },
      { code: "NL", desp: "Nederlands", value: 8 },
    ]; */
    UPDATE_NATION(state, payload) {
      state.NATION = payload;
      console.log("UPDATE_NATION", payload);
    },
    /** 3 getters: PATSTAT_CONF, PATSTAT_ACTIVE, PATSTAT_INACTIVE
        PATSTAT = [
          { code: "CONF", desp: "Bevestiging nodig", value: 9, color: "info" },
          { code: "ACTIVE", desp: "Actief", value: 10, color: "primary" },
          { code: "INACTIVE", desp: "Inactief", value: 11, color: "secondary" },
        ];
    */
    UPDATE_PATSTAT(state, payload) {
      state.PATSTAT = payload;
      console.log("UPDATE_PATSTAT", payload);
    },
    /** 1 getter: RELTYPE_PATIENT
        RELTYPE = [
        { code: "PATIENT", desp: "Patient", value: RELTYPE_PATIENT },
        { code: "DOCTOR", desp: "Doctor", value: 50 },
        { code: "FAMILY", desp: "Relative", value: 51 },
      ];
    */
    UPDATE_RELTYPE(state, payload) {
      state.RELTYPE = payload;
      console.log("UPDATE_RELTYPE", payload);
    },
    /** SESSTYPE = [
      { code: "Live", desp: "Live session", value: 52 },
      { code: "Video", desp: "Video session", value: 53 },
      { code: "Telephone", desp: "Telephone session", value: 54 },
    ]; */
    UPDATE_SESSTYPE(state, payload) {
      state.SESSTYPE = payload;
      console.log("UPDATE_SESSTYPE", payload);
    },
    CLEAR_STATE(state) {
      Object.keys(state).forEach(key => {
        state[key] = [];
      })
      console.log('CLEAR_STATE, state', JSON.stringify(state));
      // state.EDU = [];
      // state.HINS = [];
      // state.MARITAL = [];
      // state.NATION = [];
      // state.PATSTAT = [];
      // state.RELTYPE = [];
      // state.SESSTYPE = [];
    },
  },
  actions: {
    async fetchParams({ commit }) {
      console.log(
        "fetchParams called, axiosInstance",
        axiosInstance.defaults.headers.common["Authorization"]
      );
      commit("SET_LOADING", true, { root: true });
      // commit("UPDATE_ERROR", { title: null, desp: null }, { root: true });
      commit("CLEAR_ERROR_INFO", null, { root: true });

      // const result = await fetch("/patients.json");
      // const json = await result.json();
      // commit("UPDATE_PATIENTS_LIST", json);
      // await sleep(1000);
      try {
        const res = await axiosInstance.get("/REST/Params?sort=pm_type,pm_code&fl=pm_type,pm_code,pm_id,pm_description&metadata=0");
        if (res.status === 200 && res.data) {
          console.log("fetchParams res.data", res.data);
          const apiRes = res.data;
          if (apiRes.error) {
            commit(
              "UPDATE_ERROR",
              {
                title: "Fetch Params",
                desp: `${apiRes.error.message} (${apiRes.error.code})`,
              },
              { root: true }
            );
          } else if (apiRes.data) {
            console.log("fetchParams apiRes.data.length", apiRes.data.length);
            commit("SAVE_PARAMS_DATA", apiRes.data);
          }
        } else {
          commit(
            "UPDATE_ERROR",
            {
              title: "Fetch Params",
              desp: `Invalid response (${res.status}).`,
            },
            { root: true }
          );
        }
      } catch (e) {
        console.log("catch fetchParams error", e.message);
        commit(
          "UPDATE_ERROR",
          { title: "Fetch Params", desp: e.message },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
};
