<template>
  <main>
    <div class="container">
      <section
        class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"
      >
        <div class="container">
          

          <div class="row justify-content-center">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
              style="width: 450px"
            >
              <!-- <div class="d-flex py-4">
                <a class="logo d-flex align-items-center w-auto">
                  <i
                    class="bi bi-file-earmark-medical-fill fs-1 p-2 card-title"
                  ></i>
                  <span class="d-lg-block">Cotinotis</span>
                </a>
              </div> -->

              <div class="card mb-3">
                <div class="card-body">
                  <!-- <div class="pt-4 pb-3">
                    <div class="logo d-flex align-items-center w-auto">
                      <i
                        class="bi bi-file-earmark-medical-fill fs-3 py-1 pe-2"
                      ></i>
                      <span class="d-lg-block">Cotinotis</span>
                    </div>
                  </div> -->

                  <div class="py-5">
                    <!-- <div class="pb-3"> -->
                    <!-- <h5 class="card-title text-center pb-0 fs-4">Log In</h5> -->
                    <div
                      class="logo d-flex align-items-center justify-content-center w-auto position-relative">
                      <div class="d-flex align-items-center ">
                        <i class="bi bi-file-earmark-medical-fill fs-2 py-1 pe-2"></i>
                        <span class="d-lg-block">Cotinotis</span>
                      </div>
                      <select v-model="$i18n.locale" class="position-absolute form-select form-select-sm lang-selection">
                        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale.toUpperCase()}}</option>
                      </select>
                    </div>
                    <!-- <div class="d-flex align-items-center justify-content-end">
                        <select v-model="$i18n.locale" class="form-select form-select-sm" style="width: 62px; right: 0; font-size: 0.8rem;">
                          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale.toUpperCase()}}</option>
                        </select>
                      </div> -->
                  </div>

                  <form class="row g-3" @submit.prevent="handleSubmit">
                    <div class="col-12 text-start">
                      <!-- <label for="username" class="form-label small">Username or email</label> -->
                      <label for="username" class="form-label small">{{ $t("login.username") }}</label>
                      <input
                        name="username"
                        class="form-control"
                        v-model="v$.username.$model"
                        required
                        @input="clearMsg()"
                      />
                      <div
                        class="input-errors"
                        v-for="error of v$.username.$errors"
                        :key="error.$uid"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>

                    <div class="col-12 text-start">
                      <!-- <label for="password" class="form-label small">Password</label> -->
                      <label for="password" class="form-label small">{{ $t("login.password") }}</label>
                      <div class="input-group" id="show_hide_password">
                        <input
                          type="password"
                          name="password"
                          class="form-control"
                          v-model="v$.password.$model"
                          required
                          @input="clearMsg()"
                        />
                        <span
                          role="button"
                          class="input-group-text text-primary"
                          @click="togglePassword"
                        >
                          <i class="bi bi-eye-slash-fill"></i>
                        </span>
                      </div>
                      <div
                        class="input-errors"
                        v-for="error of v$.password.$errors"
                        :key="error.$uid"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                      <!-- <div class="col-12">
                        <p class="small mt-2">
                          <router-link to="/forgotPassword"
                            >Forgot your password?</router-link
                          >
                        </p>
                      </div> -->
                    </div>
                    <div class="col-12 small">
                      <!-- <router-link to="/forgotPassword">Forgot password?</router-link> -->
                      <router-link to="/forgotPassword">{{ $t("login.forgot_password") }}</router-link>
                    </div>

                    <div class="col-12">
                      <button
                        class="btn btn-primary w-100"
                        type="submit"
                        :disabled="v$.$invalid"
                      >
                        Log In
                        <!-- {{ $t("login.log_in") }} -->
                      </button>
                    </div>
                    <div class="col-12">
                      <p class="small mb-1">
                        <!-- Don't have an account? -->
                        {{ $t("login.not_have_account") }}
                        <!-- <router-link to="/signup">Create an account</router-link> -->
                        <router-link to="/signup">{{ $t("login.create_account") }}</router-link>
                      </p>
                    </div>
                    <!-- <div class="col-12 alert alert-danger mb-1 py-2">
                      <i class="bi bi-exclamation-triangle-fill me-1"></i>
                      Invalid username or password.
                    </div> -->
                    <div class="alert alert-danger mb-1 py-2" v-if="error">
                      <i class="bi bi-exclamation-triangle-fill me-1"></i>
                      {{ error }}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { requiredMsg } from "@/shared/globals";
// import { minLength, helpers } from "@vuelidate/validators";

export default {
  name: "LoginView",
  setup() {
    const username = ref("");
    const password = ref("");
    const error = ref(null);

    const store = useStore();

    const rules = {
      username: { required: requiredMsg },
      password: {
        required: requiredMsg,
        // minLength: helpers.withMessage("Minimum 6 characters", minLength(6)),
      },
    };

    const handleSubmit = async () => {
      console.log("LoginView", username.value, password.value);
      error.value = "";
      try {
        await store.dispatch("login", {
          username: username.value,
          password: password.value,
        });
      } catch (err) {
        error.value = err.message;
      }
    };

    const clearMsg = () => {
      if (error.value) {
        error.value = null;
      }
    };

    const togglePassword = () => {
      const passwordInput = document.querySelector("#show_hide_password input");
      const passwordIcon = document.querySelector("#show_hide_password i");
      if (passwordInput.getAttribute("type") == "text") {
        passwordInput.setAttribute("type", "password");
        passwordIcon.classList.add("bi-eye-slash-fill");
        passwordIcon.classList.remove("bi-eye-fill");
      } else if (passwordInput.getAttribute("type") == "password") {
        passwordInput.setAttribute("type", "text");
        passwordIcon.classList.remove("bi-eye-slash-fill");
        passwordIcon.classList.add("bi-eye-fill");
      }
    };
    return {
      handleSubmit,
      clearMsg,
      username,
      password,
      error,
      togglePassword,
      v$: useVuelidate(rules, { username, password }),
    };
  },
};
</script>
