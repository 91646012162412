import { createApp } from "vue";
import Vidle from "v-idle-3";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n"
import store from "./store";
import { axiosInstance } from "@/plugins/axios";
import LoadScript from "vue-plugin-load-script";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";

import "bootstrap/dist/js/bootstrap.min.js";

// createApp(App).use(store).use(router).use(Vidle).use(LoadScript).mount("#app");


const app = createApp(App)


app.use(router)
app.use(Vidle)
app.use(LoadScript)
app.use(i18n)
app.use(store)

app.mount('#app')

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    console.log("axiosInstance.interceptors.request - config", config);
    return config;
  },
  function (error) {
    // Do something with request error
    console.log("axiosInstance.interceptors.request - error", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("axiosInstance.interceptors.RESPONSE - response", response);
    const token = response.headers["x-user-token"];
    console.log("axiosInstance.interceptors.RESPONSE - x-user-token", token);
    if (token) {
      console.log("axiosInstance.interceptors.RESPONSE - UPDATE_AUTH_TOKEN!");
      store.commit("UPDATE_AUTH_TOKEN", token);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("axiosInstance.interceptors.RESPONSE - error", error);
    console.log(
      "axiosInstance.interceptors.RESPONSE - error.message",
      error.message
    );
    // if (error.message === "Network Error") {
    //   // console.log("axiosInstance.interceptors.RESPONSE - dispatch logout!");
    //   // store.commit("UPDATE_INFO", {
    //   //   title: "Logout",
    //   //   desp: "Session ended due to network error",
    //   // });
    //   // return store.dispatch("logout");
    //   console.log(`axiosInstance.interceptors.RESPONSE > ${error.message} - redirect to login!`);
    //   store.commit("UPDATE_INFO", {
    //     title: "Error",
    //     desp: "Session ended due to network error",
    //   });
    //   router.push({
    //     name: "Login",
    //   });
    //   return;
    // }
    return Promise.reject(error);
    // if (error.response.status) {
    //   switch (error.response.status) {
    //     case 400:
    //       //do something
    //       break;

    //     case 401:
    //       alert("session expired");
    //       break;
    //     case 403:
    //       router.replace({
    //         path: "/login",
    //         query: { redirect: router.currentRoute.fullPath },
    //       });
    //       break;
    //     case 404:
    //       alert("page not exist");
    //       break;
    //     case 502:
    //       setTimeout(() => {
    //         router.replace({
    //           path: "/login",
    //           query: {
    //             redirect: router.currentRoute.fullPath,
    //           },
    //         });
    //       }, 1000);
    //   }
    //   return Promise.reject(error.response);
    // }
  }
);
